@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* playfair-display-regular - latin */
  @font-face {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 400;
    src: local(''),
      url('/fonts/playfair-display-v29-latin-regular.woff2') format('woff2'),
      /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/fonts/playfair-display-v29-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* playfair-display-500 - latin */
  @font-face {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 500;
    src: local(''),
      url('/fonts/playfair-display-v29-latin-500.woff2') format('woff2'),
      /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/fonts/playfair-display-v29-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* playfair-display-600 - latin */
  @font-face {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 600;
    src: local(''),
      url('/fonts/playfair-display-v29-latin-600.woff2') format('woff2'),
      /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/fonts/playfair-display-v29-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* playfair-display-700 - latin */
  @font-face {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 700;
    src: local(''),
      url('/fonts/playfair-display-v29-latin-700.woff2') format('woff2'),
      /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/fonts/playfair-display-v29-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* playfair-display-800 - latin */
  @font-face {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 800;
    src: local(''),
      url('/fonts/playfair-display-v29-latin-800.woff2') format('woff2'),
      /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/fonts/playfair-display-v29-latin-800.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* hind-madurai-300 - latin */
  @font-face {
    font-family: 'Hind Madurai';
    font-style: normal;
    font-weight: 300;
    src: local(''),
      url('/fonts/hind-madurai-v11-latin-300.woff2') format('woff2'),
      /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/fonts/hind-madurai-v11-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* hind-madurai-regular - latin */
  @font-face {
    font-family: 'Hind Madurai';
    font-style: normal;
    font-weight: 400;
    src: local(''),
      url('/fonts/hind-madurai-v11-latin-regular.woff2') format('woff2'),
      /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/fonts/hind-madurai-v11-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* hind-madurai-500 - latin */
  @font-face {
    font-family: 'Hind Madurai';
    font-style: normal;
    font-weight: 500;
    src: local(''),
      url('/fonts/hind-madurai-v11-latin-500.woff2') format('woff2'),
      /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/fonts/hind-madurai-v11-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* hind-madurai-600 - latin */
  @font-face {
    font-family: 'Hind Madurai';
    font-style: normal;
    font-weight: 600;
    src: local(''),
      url('/fonts/hind-madurai-v11-latin-600.woff2') format('woff2'),
      /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/fonts/hind-madurai-v11-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* hind-madurai-700 - latin */
  @font-face {
    font-family: 'Hind Madurai';
    font-style: normal;
    font-weight: 700;
    src: local(''),
      url('/fonts/hind-madurai-v11-latin-700.woff2') format('woff2'),
      /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/fonts/hind-madurai-v11-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* titillium-web-200 - latin */
  @font-face {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 200;
    src: local(''),
      url('/fonts/titillium-web-v15-latin-200.woff2') format('woff2'),
      /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/fonts/titillium-web-v15-latin-200.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* titillium-web-300 - latin */
  @font-face {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 300;
    src: local(''),
      url('/fonts/titillium-web-v15-latin-300.woff2') format('woff2'),
      /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/fonts/titillium-web-v15-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* titillium-web-regular - latin */
  @font-face {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    src: local(''),
      url('/fonts/titillium-web-v15-latin-regular.woff2') format('woff2'),
      /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/fonts/titillium-web-v15-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* titillium-web-600 - latin */
  @font-face {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 600;
    src: local(''),
      url('/fonts/titillium-web-v15-latin-600.woff2') format('woff2'),
      /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/fonts/titillium-web-v15-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* titillium-web-700 - latin */
  @font-face {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    src: local(''),
      url('/fonts/titillium-web-v15-latin-700.woff2') format('woff2'),
      /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/fonts/titillium-web-v15-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
}

html {
  overflow: hidden;
}

.pattern {
  background-color: white;
  opacity: 0.8;
  background-image: radial-gradient(#1e293b30 0.8px, #fff 0.8px);
  background-size: 24px 24px;
}

html,
body,
body > div:first-child,
div#__next,
div#__next > div {
  height: 100%;
}

/* width */
::-webkit-scrollbar {
  width: 12px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3c3b3b3b;
  border-radius: 100vh;
  transition: all 250ms ease-in-out;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #3c3b3b4b;
  cursor: pointer;
}

.masonry-grid {
  display: flex;
  margin-left: -30px;
  width: 100%;
}
.masonry-grid-col {
  padding-left: 30px;
  background-clip: padding-box;
}

.masonry-grid-col > div {
  margin-bottom: 30px;
}

@media (max-width: 800px) {
  .masonry-grid {
    margin-left: -7.5px;
  }
  .masonry-grid-col {
    padding-left: 15px;
  }
  .masonry-grid-col > div {
    margin-bottom: 15px;
    padding-right: 15px;
  }
}
